import { SEO } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Box } from 'theme-ui';

function TermsAndConditions() {
  const { post } = useStaticQuery(graphql`
    query termsAndConditions {
      post: markdownRemark(
        frontmatter: { slug: { eq: "terms-and-conditions" } }
      ) {
        id
        htmlAst
        html
      }
    }
  `);
  return (
    <>
      <SEO title="Terms and Conditions" />
      <Box
        sx={{
          boxSizing: 'content-box',
          py: ['l', null, 'xl'],
          px: 'm',
          maxWidth: 'measure',
          mx: 'auto',
          '& ul': {
            pl: ['m', null, 0],
          },
        }}
      >
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
      </Box>
    </>
  );
}

export default TermsAndConditions;
